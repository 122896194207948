.footer {
        background-color: #EBEBEB;
    }

.socials {
	padding-top : 10px;
	text-align: center;
}

.copyright {
	padding-top : 20px;
	text-align: center;
}

.social-icon {
	padding-left : 20px;
}