div.leaflet-container {
  height: 500px;
  width: 100%;
}

.timeline-wrapper {
  position: relative;
  height: 30px;
  width:100%;
  margin-bottom: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,.65);
}

.timeline-wrapper button {
  position: absolute;
  outline: none;
  color: black;
  background: #f2f2f2;
  width: 65px;
  height: 100%;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.heatmap-timeline {
  position: absolute;
  top: 0;
  right: 15px;
  left: 80px;
  height: 100%;
}

.heatmap-timeline .time-point {
  position: absolute;
  background: white;
  border: 2px solid #272727;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  cursor: pointer;
  top: 15px;
  transform: translateX(-50%) translateY(-50%);
}

.heatmap-timeline .time-point.active {
  background: black;
}

.heatmap-timeline .time-point:hover {
  box-shadow: 0 0 5px black;
}